/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import axios from 'axios';

// ProtoTypes
// import PropTypes from 'prop-types';

// UI lib components
import { Container } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/BannerSection';
import PageContent from '../page-components/page-content';
import PopUP from '../page-components/ourNetwork/POPUP/index';

// Local API
import { ourNetworkList } from '../shared/local-links/underMenuLinks';

// Assets
import BANNER_IMG from '../images/our-network-page/banner.jpg';

// Style
import '../page-styles/our-network.scss';
import PrimaryButton from '../shared/UIKit/PrimaryButton';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function OurNetworkPage() {
  /* ******************************** CONSTANTS ********************************* */
  const TABLE_HEADER = ['Thématique', 'Nom', 'Email', 'Contact'];
  const IS_BROWSER = typeof window !== 'undefined';

  /* ********************************** HOOKS ********************************* */

  const [ourNetworkData, setourNetworkData] = useState({});
  const [PCNData, setPCNData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showPOPUP, setShowPOPUP] = useState(false);

  useEffect(() => {
    axios
      .get('https://admin522.horizon-europe.tn/api/our-networks?[populate]=*')
      .then(({ data: { data } }) => {
        setourNetworkData(data[0].attributes);
        setLoader(true);
      })
      .then(() => setLoader(false))
      .catch((error) => error);
    axios
      .get(
        // eslint-disable-next-line max-len
        'https://admin522.horizon-europe.tn/api/pcns?pagination[page]=1&pagination[pageSize]=100',
      )
      .then(({ data: { data } }) => {
        setPCNData(data);
      })
      .catch((error) => error);
  }, []);

  /* ******************************** CALLBACKS ******************************* */
  function handleClick(attributes) {
    if (IS_BROWSER) {
      localStorage.setItem('contacted_person', JSON.stringify({ attributes }));
      navigate('/PCN');
    }
  }

  /* ****************************** RENDER HELPERS **************************** */

  const Menu = ourNetworkList.map(({ id, title, show }) => {
    if (show) {
      return (
        <div key={id} className="contact-info-button">
          <h4 className="contact-info-button__title">{title}</h4>
          <PrimaryButton
            title="Lien jalios"
            navigation="https://ugpo.horizon-europe.tn/"
            theme="default"
          />
        </div>
      );
    }
    return <></>;
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <PopUP trigger={showPOPUP} setTrigger={setShowPOPUP} />
      <PageLayout loader={loader}>
        <Seo title={ourNetworkData.title} />

        <BannerSection title={ourNetworkData.title} bannerImage={BANNER_IMG} />
        <Container className="our-network-page">
          <Container className="fluid" fluid>
            <PageContent
              showUnorderedList={false}
              // eslint-disable-next-line max-len
              healthImag={`https://admin522.horizon-europe.tn${ourNetworkData.picture?.data[0].attributes.url}`}
              aboutContent1={ourNetworkData.description}
            />
          </Container>
          <div className="contact-info">
            <div className="contact-info-intro">
              <h4 className="contact-info-intro__title">Liens de contact</h4>
              <p className="contact-info-intro__paragraph">
                Ces contacts sont disponibles sur Jalios. Merci de cliquer sur
                le lien suivant pour vous rendre sur Jalios
              </p>
            </div>
            <div className="contact-info-buttons">{Menu}</div>
          </div>
          <div className="contact-info-table">
            <h4 className="contact-info-table__title">
              {ourNetworkList[0].title}
            </h4>
            <table>
              <thead>
                {TABLE_HEADER.map((name) => (
                  <td>
                    <p>{name}</p>
                  </td>
                ))}
              </thead>
              <tbody>
                {PCNData.map(({ attributes }) => (
                  <tr>
                    <td>
                      <p>{attributes.thematic}</p>
                    </td>
                    <td>
                      <p>
                        {[attributes.last_name, attributes.first_name].join(
                          ' ',
                        )}
                      </p>
                    </td>
                    <td>
                      <p>{attributes.email}</p>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="page-cluster-link"
                        onClick={() => handleClick(attributes)}
                      >
                        Contact
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Container className="menu-section fluid" fluid />

          <Container className="network-table top fluid" fluid />
        </Container>
      </PageLayout>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

OurNetworkPage.propTypes = {};

export default OurNetworkPage;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { Link } from 'gatsby';
// ProtoTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// UI local components
import CLOSE_ICON from '../../../images/icons/header/close.svg';
// Style
import './index.scss';
import { POPUP_LINK } from '../../../shared/APIs';

/* -------------------------------------------------------------------------- */
/*                           Menu Bar Langin Page                             */
/* -------------------------------------------------------------------------- */

function PopUP({ trigger, setTrigger }) {
  /* ****************************** RENDERING ******************************* */
  return trigger ? (
    <div className="PopUP-container fluid">
      <Container className="PopUP-container__inner flex-center">
        <div className="close-Icon">
          <img src={CLOSE_ICON} alt="close" onClick={() => setTrigger(false)} />
        </div>
        <p>
          Ces contacts sont disponibles sur Jalios. Merci de cliquer sur le lien
          suivant pour vous rendre sur Jalios
        </p>
        <div className="Link-container">
          <button type="submit">
            <Link className="Button" to={POPUP_LINK}>
              lien Jalios
            </Link>
          </button>
        </div>
      </Container>
    </div>
  ) : (
    ''
  );
}

PopUP.propTypes = {
  trigger: PropTypes.bool.isRequired,
  setTrigger: PropTypes.func.isRequired,
};

export default PopUP;

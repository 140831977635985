export const List = [
  {
    id: 1,
    title: ' Santé',
    link: '/',
  },
  {
    id: 2,
    title: 'Culture, créativité et société inclusive',
    link: '/#about',
  },
  {
    id: 3,
    title: 'Sécurité civile pour la société',
    link: '/#events',
  },
  {
    id: 4,
    title: 'Numérique, industrie et espace',
    link: '/#actualities',
  },
  {
    id: 5,
    title: 'Climat, énergie et mobilité',
    link: '/#PCN',
  },
  {
    id: 6,
    title: 'Climat, énergie et mobilité',
    link: '/#usefullLinks',
  },
];

export const ourNetworkList = [
  {
    id: 1,
    title: 'Points de Contact Nationaux',
    link: '/our-network#PCN',
    show: false,
  },
  {
    id: 2,
    title: 'Points Focaux Institutionnels ',
    link: '/our-network#PFI',
    show: true,
  },
  {
    id: 3,
    title: 'Cellules de montage de projets',
    link: '/our-network#PFI',
    show: true,
  },
  {
    id: 4,
    title: 'Réseaux thématiques Nationaux',
    link: '/our-network#PFI',
    show: true,
  },
];

export const understandHorizonList = [
  {
    id: 1,
    title: 'Présentation du programme',
    link: '/understand-horizon',
  },
  {
    id: 2,
    title: 'Ressources juridiques et financières',
    link: '/understand-horizon',
  },
];

export const otherNeworksList = [
  {
    id: 1,
    title: 'Euraxess',
    link: '/other-networks-and-programs/#Euraxess',
  },
  {
    id: 2,
    title: 'Prima',
    link: '/other-networks-and-programs/#Prima',
  },
  {
    id: 3,
    title: 'Bluemed',
    link: '/other-networks-and-programs/#Bluemed',
  },
];

export const TraingAndCoachingList = [
  {
    id: 1,
    title: 'Formation Communauté',
    link: '/training-and-coaching#Community',
  },
  {
    id: 2,
    title: 'Formation Gouvernance',
    link: '/training-and-coaching#Governance',
  },
  {
    id: 3,
    title: 'Formation Réseaux',
    link: '/training-and-coaching#Network',
  },
  {
    id: 4,
    title: 'Coaching Individuel',
    link: '/training-and-coaching#Individual',
  },
];

/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                           ABOUT US UNORDERED LIST                          */
/* -------------------------------------------------------------------------- */
export const List = [
  {
    id: 1,
    link: 'Assurer la coordination et le suivi du bon fonctionnement du programme.',
  },
  {
    id: 2,
    link: 'Planifier et orienter afin d’intégrer le système national de recherche et innovation (R&I) dans l’espace européen de la recherche,.',
  },
  {
    id: 3,
    link: 'Définir les priorités de la R&I à défendre auprès des comités programmes Horizon Europe.',
  },

  {
    id: 4,
    link: 'Réaliser des campagnes d’information et de sensibilisation, destinées à la communauté R&I en vue de disséminer les objectifs et les opportunités du programme,',
  },
  {
    id: 5,
    link: 'Promouvoir la participation de la Tunisie auprogramme Horizon Europe tant au niveau national qu’international.',
  },
  {
    id: 6,
    link: 'Elaborer des plans de formation et de coaching au profit de la communauté R&I et veiller à leur exécution et leur évaluation.',
  },
  {
    id: 7,
    link: 'Maximiser et appuyer la participation des startups tunisiennes dans le cadre du programme.',
  },
  {
    id: 8,
    link: 'Désigner des experts nationaux en tant queconseillers scientifiques pour représenter la Tunisieaux comités programmes de la commission européenne.',
  },
  {
    id: 9,
    link: 'Nommer, appuyer et préparer les points de contactnationaux auprès de la commission européenne pour informer, orienter et conseiller la communauté R&I.',
  },
  {
    id: 10,
    link: 'Appuyer la mise en place des cellules de montage de projets européens dans les établissements d’enseignement supérieur et de la recherche scientifique.',
  },
  {
    id: 11,
    link: 'Suivre les subventions dans le cadre unique d’association Tunisie-Union Européenne 2021-2027 et les actions de jumelage pour le renforcement de la participation tunisienne au programme Horizon Europe, et ce pour un meilleur accompagnement de la réalisation des objectifs programmés dans le cadre de la réforme du système national de R&I.',
  },
];

/* -------------------------------------------------------------------------- */
/*            UNDERSTAND HORIZON PAGE - PRESENTATION UNORDERD LIST            */
/* -------------------------------------------------------------------------- */
export const HORIZON_PRESENTATION_LIST = [
  {
    id: 1,
    link: "Renforcer l'excellente de la recherche fondamentale et exploratoire",
  },
  {
    id: 2,
    link: 'Renforcer le lien entre R& I',
  },
  {
    id: 3,
    link: "Soutenir la mise en œuvre des priorités politiques de l'Union, notamment les objectifs de développement durable.",
  },
  {
    id: 4,
    link: 'Promouvoir une recherche et une innovation responsables',
  },
  {
    id: 5,
    link: 'Renforcer la dimension de genre dans tout le programme',
  },
  {
    id: 6,
    link: 'Renforcer les liens entre les secteurs et les disciplines',
  },
  {
    id: 7,
    link: 'Renforcer la coopération internationale',
  },
  {
    id: 8,
    link: 'Développer des infrastructures de recherche dans tout l’EER',
  },
  {
    id: 9,
    link: 'Attirer les talents, former et ﬁdéliser les chercheurs et les innovateurs dans l’EER',
  },
  {
    id: 10,
    link: 'Promouvoir la science ouverte',
  },
  {
    id: 11,
    link: "Encourager l'exploitation des résultats de la R&I",
  },
  {
    id: 12,
    link: 'Réaliser, par le biais de missions de R&I, des objectifs ambitieux dans un délai ﬁxé',
  },
  {
    id: 13,
    link: "Améliorer la relation et l'interaction entre la science et la société",
  },
  {
    id: 14,
    link: 'Accélérer la transformation industrielle',
  },
  {
    id: 15,
    link: 'Stimuler les activités de R&I dans les PME et start-ups',
  },
];

/* -------------------------------------------------------------------------- */
/*            UNDERSTAND HORIZON PAGE - PRESENTATION UNORDERD LIST            */
/* -------------------------------------------------------------------------- */

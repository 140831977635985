/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container } from 'react-grid-system';

// Local API
import {
  List,
  HORIZON_PRESENTATION_LIST,
  HORIZON_FINANCIAL_AND_LAW_RESSOURCES_LIST,
} from '../../shared/local-links/list';

// Assets
import ADD_ICON from '../../images/understand-horizon-page/add.svg';
import MINUS_ICON from '../../images/understand-horizon-page/minus.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function PageContent({
  buttonValue,
  showUnorderedList,
  showTrainingList,
  showUnderlinedParagraphs,
  showHorizonFinancialRessources,
  healthImag,
  aboutContent1,
}) {
  /* ********************************* HOOKS ********************************* */
  const [showRessource, setShowRessource] = useState({
    id: '',
    isOpen: false,
  });

  /* ******************************* CALLBACKS ******************************** */
  const onShowResources = (id) => {
    setShowRessource({
      id,
      isOpen: !showRessource.isOpen,
    });
  };
  /* ***************************** RENDER HELPERS ***************************** */

  function getUnorderedList() {
    if (showUnorderedList) {
      if (buttonValue === 'Présentation du programme') {
        HORIZON_PRESENTATION_LIST.map((list) => (
          <li key={list.id}>
            <h5>{list.link}</h5>
          </li>
        ));
      }
      return List.map((list) => (
        <li key={list.id}>
          <h5>{list.link}</h5>
        </li>
      ));
    }
    return '';
  }

  function getHorizonFinancialRessources() {
    if (showHorizonFinancialRessources) {
      return HORIZON_FINANCIAL_AND_LAW_RESSOURCES_LIST.map((list) => (
        <li className="financial-ressource-item" key={list.id}>
          <Container className="flex items-start fluid">
            <Container
              className="fluid"
              onClick={() => onShowResources(list.id)}
            >
              {showRessource.isOpen && showRessource.id === list.id ? (
                <img
                  className="control-icon"
                  src={MINUS_ICON}
                  alt="minus_icon"
                />
              ) : (
                <img className="control-icon" src={ADD_ICON} alt="add_icon" />
              )}
            </Container>

            <h5>{list.link}</h5>
          </Container>
          {showRessource.isOpen && showRessource.id === list.id && (
            <ul>
              {list.subLink.map((sublink) => (
                <li key={sublink.id}>
                  <a href={sublink.url} target="_blank" rel="noreferrer">
                    <h5>{sublink.name}</h5>
                  </a>
                </li>
              ))}
            </ul>
          )}
        </li>
      ));
    }
    return '';
  }

  function getUnderlinedParagraphs() {
    if (showUnderlinedParagraphs) {
      return List.map((paragraph) => (
        <p className="single-underlined-paragraph" key={paragraph.id}>
          {paragraph.link}
        </p>
      ));
    }
    return '';
  }

  function getTrainingList() {
    if (showTrainingList) {
      return (
        <>
          {List.map((paragraph) => (
            <p className="single-training-paragraph" key={paragraph.id}>
              {paragraph.link}
            </p>
          ))}
          <button type="button">DEMANDER</button>
        </>
      );
    }
    return '';
  }

  function getImage() {
    if (healthImag) {
      return (
        <Container className="image fluid" fluid>
          <img src={healthImag} alt="horizon img" />
        </Container>
      );
    }
    return '';
  }

  /* ******************************** RENDERING ******************************* */
  return (
    <div className="page-content-demo flex-column">
      <div className="flex flex-column">
        <p className="about-desc">{aboutContent1}</p>
        {getImage()}
      </div>

      <Container className="description fluid">
        {showUnorderedList && (
          <h2 className="about-title">Objectifs & Missions</h2>
        )}
        <ul className="top">{getUnorderedList()}</ul>
        <ul>{getHorizonFinancialRessources()}</ul>
        {getUnderlinedParagraphs()}
        {getTrainingList()}
      </Container>
    </div>
  );
}

PageContent.propTypes = {
  buttonValue: PropTypes.string.isRequired,
  healthImag: PropTypes.string.isRequired,
  showUnorderedList: PropTypes.bool,
  showTrainingList: PropTypes.bool,
  showUnderlinedParagraphs: PropTypes.bool,
  showHorizonFinancialRessources: PropTypes.bool,
  aboutContent1: PropTypes.string.isRequired,
};

PageContent.defaultProps = {
  showUnorderedList: true,
  showTrainingList: false,
  showUnderlinedParagraphs: false,
  showHorizonFinancialRessources: false,
};

export default PageContent;
